/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.hidden,
.d-none {
    display: none;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.text-center {
    text-align: center;
}

.info {
    border: 1px solid var(--ion-color-primary);
    border-radius: 10px;
    padding: 0.5em;
    margin: 10px 10px;
}

ion-content {
    --background: var(--ion-color-secondary);

    .header-container {
        .title,
        .description {
            color: white;
        }
    }

    ion-list-header ion-label,
    ion-searchbar,
    form ion-label,
    h1, h2, h3, h4, h5, h6 {
        color: white;
        --color: white;
    }
}

ion-tab-button {

    ion-icon {
        font-size: 1.6rem;
    }

    &.tab-selected ion-icon {

        &:after {
            content: " ";
            background: var(--ion-color-primary);
            width: 5px;
            height: 5px;
            border-radius: 50%;
            display: block;
            position: absolute;
            bottom: 0;
            margin: auto;
            left: 42%;
        }

    }
}

.text-capitalize {
    text-transform: capitalize;
}
